const LOCATIONS = [
  { name: 'Lviv', lat: 49.5033, lon: 24.0156 },
  { name: 'Peremyshliany', lat: 49.4014, lon: 24.3336 },
  { name: 'Pustomyty', lat: 49.4314, lon: 23.5420 },
  { name: 'Boryslav', lat: 49.1721, lon: 23.2508 },
  { name: 'Drohobych', lat: 49.21, lon: 23.30 },
  { name: 'Skhidnytsia', lat: 49.1325, lon: 23.2111 },
  { name: 'Truskavets', lat: 49.1640, lon: 23.3028 },
  { name: 'Radekhiv', lat: 50.1642, lon: 24.3842 },
  { name: 'Sokal', lat: 50.29, lon: 24.17 },
  { name: 'Chervonohrad', lat: 50.2312, lon: 24.1344 },
  { name: 'Zhydachiv', lat: 49.2306, lon: 24.0840 },
  { name: 'Stryi', lat: 49.15, lon: 23.51 },
  { name: 'Skole', lat: 49.0216, lon: 23.3050 },
  { name: 'Morshyn', lat: 49.0917, lon: 23.5207 },
];

export default LOCATIONS;
